import React from 'react';
import PropTypes from 'prop-types';

import Style from './Progress.module.scss';


/**
 * プログレスコンポーネントです。
 */
const Progress = (props) => {
  const { isShow } = props;

  return (
    <div className={[Style.outer, isShow ? Style.show : Style.hide].join(' ')}>
      <div className={Style.inner}>
        <div className="progress">
          <div className={`${Style.bar} progress-bar progress-bar-striped progress-bar-animated`} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" />
        </div>
      </div>
    </div>
  );
};

Progress.propTypes = {
  isShow: PropTypes.bool.isRequired,
};

export default Progress;
