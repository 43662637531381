import React from 'react';
import PropTypes from 'prop-types';

import Progress from './Progress';
import ResizeTool from './tool/ResizeTool';
import CropTool from './tool/CropTool';
import SaveTool from './tool/SaveTool';

import Style from './Canvas.module.scss';

/* global Image, */


/**
 * キャンバスコンポーネントです。
 */
export default class Canvas extends React.Component {
  /**
   * コンストラクタです。
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.state = {
      progressIsShow: false,
    };

    this.canvas = {};

    // Undo & Redo 用の画像情報を保持する変数
    this.current = undefined;
    this.undoStack = [];
    this.redoStack = [];
  }

  /**
   * キャンバスへの参照を登録します。
   */
  assignRefOfCanvas = (e) => {
    this.canvas = e;
  }

  /**
   * キャンバスの情報を取得します。
   */
  getCanvasInf = () => ({
    width: this.canvas.clientWidth,
    height: this.canvas.clientHeight,
  })

  /**
   * 画像の情報を取得します。
   */
  getImageInf = () => {
    const { imageSrc, mimeType, fileName } = this.props;

    const image = new Image();

    image.src = imageSrc;

    return {
      width: image.width,
      height: image.height,
      mimeType,
      fileName,
    };
  }

  /**
   * 画像を取得します。
   */
  getImage = () => {
    const { imageSrc } = this.props;

    this.setState({ progressIsShow: true });
    return imageSrc;
  }

  /**
   * 画像を設定します。
   * @param {*} image 設定する画像
   */
  setImage = (imageSrc) => {
    const { onChangeImage } = this.props;

    this.handleDo(imageSrc);

    onChangeImage(imageSrc);
    this.clearProgress();
  }

  /**
   * プログレスバーの表示を解除します。
   */
  clearProgress = () => {
    this.setState({ progressIsShow: false });
  }

  /**
   * Do コマンドを管理します。
   */
  handleDo = (imageSrc) => {
    // Undo & Redo を管理する
    if (this.current !== undefined) {
      this.undoStack.push(this.current);
    }
    this.current = imageSrc;
    this.redoStack.length = 0;
  }

  /**
   * Undo コマンドを管理します。
   */
  handleUndo = () => {
    const { onChangeImage } = this.props;

    this.redoStack.push(this.current);
    this.current = this.undoStack.pop();

    onChangeImage(this.current);
  }

  /**
   * Redo コマンドを管理します。
   */
  handleRedo = () => {
    const { onChangeImage } = this.props;

    this.undoStack.push(this.current);
    this.current = this.redoStack.pop();

    onChangeImage(this.current);
  }

  /**
   * 画像をクリアし、画像のアップロード待ちの状態にします。
   */
  handleClearImage = () => {
    const { onClearImage } = this.props;

    onClearImage();
  }

  /**
   * 描画します。
   */
  render() {
    const { activate, imageSrc } = this.props;
    const { progressIsShow } = this.state;

    const canUndo = this.undoStack.length > 0;
    const canRedo = this.redoStack.length > 0;

    return (
      <div className={[Style.canvas, activate ? '' : Style.inactive].join(' ')}>
        <img className={Style.image} src={imageSrc} alt="canvas" ref={this.assignRefOfCanvas} />
        <div className={Style.toolBox}>
          <button type="button" className="btn tool" onClick={this.handleClearImage}><i className="material-icons">add_photo_alternate</i></button>
          <button type="button" className="btn tool" onClick={this.handleUndo} disabled={!canUndo}><i className="material-icons">undo</i></button>
          <button type="button" className="btn tool" onClick={this.handleRedo} disabled={!canRedo}><i className="material-icons">redo</i></button>
          <ResizeTool
            getImageInf={this.getImageInf}
            getImage={this.getImage}
            setImage={this.setImage}
          />
          <CropTool
            getCanvasInf={this.getCanvasInf}
            getImageInf={this.getImageInf}
            getImage={this.getImage}
            setImage={this.setImage}
          />
          <SaveTool
            getImageInf={this.getImageInf}
            getImage={this.getImage}
            clearProgress={this.clearProgress}
          />
        </div>
        <Progress isShow={progressIsShow} />
      </div>
    );
  }
}

Canvas.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  mimeType: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  activate: PropTypes.bool.isRequired,
  onClearImage: PropTypes.func.isRequired,
  onChangeImage: PropTypes.func.isRequired,
};
