import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import Jimp from 'jimp';

import Style from './ResizeTool.module.scss';

/**
 * リサイズツールコンポーネントです。
 */
export default class ResizeTool extends React.Component {
  /**
   * コンストラクタです。
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      width: 0,
      height: 0,
      isLockAspectRatio: true,
    };
  }

  /**
   * モーダルダイアログを開きます。
   */
  openModal = () => {
    const { getImageInf } = this.props;

    this.imageInf = getImageInf();
    this.setState({
      modalIsOpen: true,
      width: this.imageInf.width,
      height: this.imageInf.height,
      widthPercent: 100,
      heightPercent: 100,
    });
  }

  /**
   * モーダルダイアログが表示された後に呼ばれます。
   */
  afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = '#f00';
  }

  /**
   * モーダルダイアログを閉じます。
   */
  closeModal = () => {
    this.setState({ modalIsOpen: false });
  }

  /**
   * 「width」の変更を受け取ります。
   * @param {*} e
   */
  changeWidth = (e) => {
    const { isLockAspectRatio } = this.state;

    if (Number.isFinite(Number(e.target.value))) {
      const widthRate = e.target.value / this.imageInf.width;

      if (isLockAspectRatio) {
        this.setState({
          width: e.target.value,
          height: Math.round(this.imageInf.height * widthRate),
          widthPercent: Math.round(widthRate * 100),
          heightPercent: Math.round(widthRate * 100),
        });
      } else {
        this.setState({
          width: e.target.value,
          widthPercent: Math.round(widthRate * 100),
        });
      }
    }
  }

  /**
   * 「height」の変更を受け取ります。
   * @param {*} e
   */
  changeHeight = (e) => {
    const { isLockAspectRatio } = this.state;

    if (Number.isFinite(Number(e.target.value))) {
      const heightRate = e.target.value / this.imageInf.height;

      if (isLockAspectRatio) {
        this.setState({
          width: Math.round(this.imageInf.width * heightRate),
          height: e.target.value,
          widthPercent: Math.round(heightRate * 100),
          heightPercent: Math.round(heightRate * 100),
        });
      } else {
        this.setState({
          height: e.target.value,
          heightPercent: Math.round(heightRate * 100),
        });
      }
    }
  }

  changeWidthRate = (e) => {
    const { isLockAspectRatio } = this.state;

    if (Number.isFinite(Number(e.target.value))) {
      const width = Math.round(this.imageInf.width * e.target.value / 100);

      if (isLockAspectRatio) {
        this.setState({
          width,
          height: Math.round(this.imageInf.height * e.target.value / 100),
          widthPercent: e.target.value,
          heightPercent: e.target.value,
        });
      } else {
        this.setState({
          width,
          widthPercent: e.target.value,
        });
      }
    }
  }

  changeHeightRate = (e) => {
    const { isLockAspectRatio } = this.state;

    if (Number.isFinite(Number(e.target.value))) {
      const height = Math.round(this.imageInf.height * e.target.value / 100);

      if (isLockAspectRatio) {
        this.setState({
          width: Math.round(this.imageInf.height * e.target.value / 100),
          height,
          widthPercent: e.target.value,
          heightPercent: e.target.value,
        });
      } else {
        this.setState({
          height,
          heightPercent: e.target.value,
        });
      }
    }
  }

  /**
   * 「縦横比固定」の変更を受け取ります。
   * @param {*} e
   */
  changeLockAspectRatio = (e) => {
    this.setState({ isLockAspectRatio: e.target.checked });
  }

  /**
   * 画像をリサイズします。
   */
  transform = () => {
    const {
      getImageInf,
      getImage,
      setImage,
    } = this.props;

    const {
      width,
      height,
    } = this.state;

    const prevWidth = Number(this.imageInf.width);
    const prevHeight = Number(this.imageInf.height);
    const newWidth = Number(width === 0 ? prevWidth : width);
    const newHeight = Number(height === 0 ? prevHeight : height);

    this.closeModal();

    if (newWidth === prevWidth && newHeight === prevHeight) {
      // サイズに変更がない場合は何もしない
      return;
    }

    Jimp.read(getImage()).then((jimpImage) => {
      // リサイズ処理
      jimpImage.resize(newWidth, newHeight);

      jimpImage.getBase64Async(getImageInf().mimeType).then((imageSrc) => {
        setImage(imageSrc);
      }).catch((err) => {
        console.error(err);
      });
    }).catch((err) => {
      console.error(err);
    });
  }

  /**
   * 描画します。
   */
  render() {
    const {
      modalIsOpen,
      width,
      height,
      widthPercent,
      heightPercent,
      isLockAspectRatio,
    } = this.state;

    const newWidth = 'newWidth';
    const newHeight = 'newHeight';
    const newWidthPercent = 'newWidthPercent';
    const newHeightPercent = 'newHeightPercent';
    const lockAspectRatioCheck = 'lockAspectRatioCheck';

    return (
      <div>
        <button type="button" onClick={this.openModal} className="btn tool"><i className="material-icons">photo_size_select_large</i></button>

        <ReactModal
          isOpen={modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          className={Style.modalDialog}
          overlayClassName={Style.modalOverlay}
          contentLabel="Resize Modal"
        >

          <div className="card">
            <div className="card-header">
              Resize
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="card-body">
              <div className="form-row">
                <div className="form-group col-sm-6">
                  <label htmlFor={newWidth}>width:</label>
                  <input type="text" id={newWidth} className="form-control form-control-sm" value={width} onChange={this.changeWidth} />
                </div>
                <div className="form-group col-sm-6">
                  <label htmlFor={newHeight}>height:</label>
                  <input type="text" id={newHeight} className="form-control form-control-sm" value={height} onChange={this.changeHeight} />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-sm-6">
                  <input type="text" id={newWidthPercent} className="form-control form-control-sm" value={widthPercent} onChange={this.changeWidthRate} />
                </div>
                <div className="form-group col-sm-6">
                  <input type="text" id={newHeightPercent} className="form-control form-control-sm" value={heightPercent} onChange={this.changeHeightRate} />
                </div>
              </div>
              <div className="form-group">
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" id={lockAspectRatioCheck} checked={isLockAspectRatio} onChange={this.changeLockAspectRatio} />
                  <label className="form-check-label" htmlFor={lockAspectRatioCheck}>Lock aspect ratio</label>
                </div>
              </div>
            </div>

            <div className="card-footer text-right">
              <button type="button" className="btn btn-primary btn-sm" onClick={this.transform}>Save changes</button>
            </div>
          </div>

        </ReactModal>
      </div>
    );
  }
}

ResizeTool.propTypes = {
  getImageInf: PropTypes.func.isRequired,
  getImage: PropTypes.func.isRequired,
  setImage: PropTypes.func.isRequired,
};
