import React from 'react';

import DragAndDrop from './DragAndDrop';
import Canvas from './Canvas';

import Style from './Main.module.scss';

const URL_BLANK_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
const MODE_DRAG_AND_DROP = 'DRAG_AND_DROP';
const MODE_CANVAS = 'CANVAS';


/**
 * メインコンポーネントです。
 */
export default class Main extends React.Component {
  /**
   * コンストラクタです。
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.state = {
      mode: MODE_DRAG_AND_DROP,
      imageSrc: URL_BLANK_IMAGE,
      mimeType: 'image/gif',
      fileName: 'new',
    };

    this.canvas = {};
  }

  /**
   * キャンバスへの参照を登録します。
   */
  assignRefOfCanvas = (e) => {
    this.canvas = e;
  }

  /**
   * 画像をブランクにし、ドラッグ&ドロップモードにします。
   */
  onClearImage = () => {
    this.setState({
      mode: MODE_DRAG_AND_DROP,
      imageSrc: URL_BLANK_IMAGE,
    });
  }

  /**
   * 指定の画像で差し替えます。
   * @param {*} imageSrc 画像のソース
   */
  onChangeImage = (imageSrc) => {
    this.setState({
      mode: MODE_CANVAS,
      imageSrc,
    });
  }

  /**
   * 指定の画像を設定します。
   * @param {*} imageSrc 画像のソース
   */
  onLoadImage = (imageSrc, mimeType, fileName) => {
    this.setState({
      mode: MODE_CANVAS,
      imageSrc,
      mimeType,
      fileName,
    });

    this.canvas.handleDo(imageSrc);
  }

  /**
   * 描画します。
   */
  render() {
    const {
      mode, imageSrc, mimeType, fileName,
    } = this.state;

    return (
      <div className={Style.main}>
        <Canvas
          ref={this.assignRefOfCanvas}
          imageSrc={imageSrc}
          mimeType={mimeType}
          fileName={fileName}
          onClearImage={this.onClearImage}
          onChangeImage={this.onChangeImage}
          activate={mode === MODE_CANVAS}
        />
        <DragAndDrop
          onLoadImage={this.onLoadImage}
          activate={mode === MODE_DRAG_AND_DROP}
        />
      </div>
    );
  }
}
