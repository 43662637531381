import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import ReactEventListener from 'react-event-listener';
import Jimp from 'jimp';
import Konva from 'konva';

import Style from './CropTool.module.scss';

/**
 * リサイズツールコンポーネントです。
 */
export default class CropTool extends React.Component {
  /**
   * コンストラクタです。
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.state = {
      isCropMode: false,
      modalIsOpen: false,
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      isLockAspectRatio: false,
    };

    /* eslint no-use-before-define: ["error", { "classes": false }] */
    this.size = new SizeManager();

    // 領域を選択中かを表すフラグ
    this.isSelecting = false;
    // 領域が選択済みかを表すフラグ
    this.isSelected = false;

    this.timer = 0;
  }

  /**
   * React のライフサイクルを参照してください。
   */
  componentDidUpdate() {
    const { getCanvasInf, getImageInf } = this.props;
    const { isLockAspectRatio } = this.state;

    this.size.setScale(getCanvasInf().width / getImageInf().width);
    this.crateCanvasForCrop(getCanvasInf(), isLockAspectRatio);
  }

  /**
   * ブラウザのウィンドウサイズの変更を受け取ります。
   */
  handleResize = () => {
    const { getCanvasInf, getImageInf } = this.props;
    const { isLockAspectRatio } = this.state;

    if (this.timer > 0) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      this.size.setScale(getCanvasInf().width / getImageInf().width);
      this.crateCanvasForCrop(getCanvasInf(), isLockAspectRatio);
    }, 300);
  }

  /**
   * 領域選択用のキャンバスを作成します。
   */
  crateCanvasForCrop = (canvasInf, isLockAspectRatio) => {
    const stage = new Konva.Stage({
      container: 'crop-container',
      width: canvasInf.width,
      height: canvasInf.height,
    });

    const layer = new Konva.Layer();

    const createSelector = () => {
      const selector = new Konva.Rect({
        x: this.size.getForCanvas().x,
        y: this.size.getForCanvas().y,
        width: this.size.getForCanvas().width,
        height: this.size.getForCanvas().height,
        draggable: true,
      });
      selector.on('dragend', () => {
        this.size.setForCanvas({
          x: selector.x(),
          y: selector.y(),
        });
      });
      selector.on('transformend', () => {
        this.size.setForCanvas({
          x: selector.x(),
          y: selector.y(),
          width: selector.width() * selector.scaleX(),
          height: selector.height() * selector.scaleY(),
        });
        this.size.correctMinusValue();
      });

      return selector;
    };
    const createTransformer = (node, keepRatio) => {
      const tr1 = new Konva.Transformer({
        node,
        centeredScaling: false,
        rotateEnabled: false,
        keepRatio,
      });
      return tr1;
    };

    let selector = null;

    if (this.isSelected) {
      // 既に選択済みの場合は選択領域を作成する
      selector = createSelector();
      const tr1 = createTransformer(selector, isLockAspectRatio);

      layer.removeChildren();
      layer.add(selector);
      layer.add(tr1);
    }

    stage.on('mousedown', (e) => {
      if (e.target !== stage) {
        return;
      }

      this.isSelecting = true;

      const point = stage.getPointerPosition();
      this.size.setForCanvas({
        x: point.x,
        y: point.y,
        width: 0,
        height: 0,
      });

      selector = createSelector();
      const tr1 = createTransformer(selector, isLockAspectRatio);

      layer.removeChildren();
      layer.add(selector);
      layer.add(tr1);
      layer.draw();
    });
    stage.on('mousemove', () => {
      if (this.isSelecting) {
        const point = stage.getPointerPosition();
        const size = {
          width: point.x - this.size.getForCanvas().x,
          height: point.y - this.size.getForCanvas().y,
        };
        this.size.setForCanvas(size);

        selector.size(size);

        layer.draw();
      }
    });
    stage.on('mouseup', () => {
      if (this.isSelecting) {
        this.size.correctMinusValue();

        this.isSelecting = false;
        this.isSelected = true;

        layer.draw();
      }
    });

    stage.add(layer);
  }

  /**
   *
   */
  toggleCropMode = (isCropMode) => {
    if (!isCropMode) {
      this.size.init();
      this.isSelected = false;
    }
    this.setState({ isCropMode });
  }

  /**
   * モーダルダイアログを開きます。
   */
  openModal = () => {
    const { getImageInf } = this.props;

    let size;
    if (this.isSelected) {
      size = this.size.getForImageWithRound();
    } else {
      size = {
        x: 0,
        y: 0,
        width: getImageInf().width,
        height: getImageInf().height,
      };
    }

    this.setState({
      modalIsOpen: true,
      x: size.x,
      y: size.y,
      width: size.width,
      height: size.height,
    });
  }

  /**
   * モーダルダイアログを閉じます。
   */
  closeModal = () => {
    this.setState({ modalIsOpen: false });
  }

  /**
   * 「x座標」の変更を受け取ります。
   */
  changeX = (e) => {
    if (Number.isFinite(Number(e.target.value))) {
      this.setState({
        x: e.target.value,
      });
    }
  }

  /**
   * 「y座標」の変更を受け取ります。
   */
  changeY = (e) => {
    if (Number.isFinite(Number(e.target.value))) {
      this.setState({
        y: e.target.value,
      });
    }
  }

  /**
   * 「横の長さ」の変更を受け取ります。
   */
  changeWidth = (e) => {
    const { getImageInf } = this.props;
    const { isLockAspectRatio } = this.state;

    if (Number.isFinite(Number(e.target.value))) {
      if (isLockAspectRatio) {
        const heightRate = e.target.value / getImageInf().width;
        this.setState({
          width: e.target.value,
          height: Math.round(getImageInf().height * heightRate),
        });
      } else {
        this.setState({
          width: e.target.value,
        });
      }
    }
  }

  /**
   * 「縦の長さ」の変更を受け取ります。
   */
  changeHeight = (e) => {
    const { getImageInf } = this.props;
    const { isLockAspectRatio } = this.state;

    if (Number.isFinite(Number(e.target.value))) {
      if (isLockAspectRatio) {
        const widthRate = e.target.value / getImageInf().height;
        this.setState({
          width: Math.round(getImageInf().width * widthRate),
          height: e.target.value,
        });
      } else {
        this.setState({
          height: e.target.value,
        });
      }
    }
  }

  /**
   * 「縦横比固定」の変更を受け取ります。
   */
  changeLockAspectRatio = (e) => {
    this.setState({ isLockAspectRatio: e.target.checked });
  }

  /**
   * 選択領域を更新します。
   */
  updateSelector = () => {
    const {
      x, y, width, height,
    } = this.state;

    this.size.setForImage({
      x, y, width, height,
    });

    this.isSelected = true;

    this.closeModal();
  }

  /**
   * 画像をトリミングします。
   */
  transform = () => {
    const {
      getImageInf, getImage, setImage,
    } = this.props;

    let {
      x, y, width, height,
    } = this.size.getForImage();

    x = x < 0 ? 0 : x;
    y = y < 0 ? 0 : y;
    width = width > getImageInf().width ? getImageInf().width : width;
    height = height > getImageInf().height ? getImageInf().height : height;

    const isNotSelect = width === 0 && height === 0;
    const isNotChange = x === 0 && y === 0
      && width === getImageInf().width && height === getImageInf().height;

    if (isNotSelect || isNotChange) {
      // サイズに変更がない場合は何もしない
      return;
    }

    Jimp.read(getImage()).then((jimpImage) => {
      // トリミング処理
      jimpImage.crop(x, y, width, height);

      jimpImage.getBase64Async(getImageInf().mimeType).then((imageSrc) => {
        setImage(imageSrc);

        this.toggleCropMode(false);
      }).catch((err) => {
        console.error(err);
      });
    }).catch((err) => {
      console.error(err);
    });
  }

  /**
   * 描画します。
   */
  render() {
    const {
      isCropMode, modalIsOpen, x, y, width, height, isLockAspectRatio,
    } = this.state;

    const newX = 'newX';
    const newY = 'newY';
    const newWidth = 'newWidth';
    const newHeight = 'newHeight';
    const lockAspectRatioCheck = 'lockAspectRatioCheck';


    return (
      <div className={Style.toolMain}>

        <ReactEventListener target="window" onResize={this.handleResize} />

        <div id="crop-container" className={[Style.canvas, isCropMode ? Style.show : Style.hide].join(' ')} />

        <button type="button" onClick={() => this.toggleCropMode(!isCropMode)} className="btn tool"><i className="material-icons">crop</i></button>
        <div className={[Style.toolSub, isCropMode ? '' : Style.hide].join(' ')}>
          <button type="button" onClick={this.transform}><i className="material-icons">done</i></button>
          <button type="button" onClick={this.openModal}><i className="material-icons">settings</i></button>
          <button type="button" onClick={() => this.toggleCropMode(false)}><i className="material-icons">clear</i></button>
        </div>

        <ReactModal
          isOpen={modalIsOpen}
          onRequestClose={this.closeModal}
          className={Style.modalDialog}
          overlayClassName={Style.modalOverlay}
          contentLabel="Crop Modal"
        >

          <div className="card">
            <div className="card-header">
              Crop
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="card-body">
              <div className="form-row">
                <div className="form-group col-sm-6">
                  <label htmlFor={newX}>x:</label>
                  <input type="text" id={newX} className="form-control form-control-sm" value={x} onChange={this.changeX} />
                </div>
                <div className="form-group col-sm-6">
                  <label htmlFor={newY}>y:</label>
                  <input type="text" id={newY} className="form-control form-control-sm" value={y} onChange={this.changeY} />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-sm-6">
                  <label htmlFor={newWidth}>width:</label>
                  <input type="text" id={newWidth} className="form-control form-control-sm" value={width} onChange={this.changeWidth} />
                </div>
                <div className="form-group col-sm-6">
                  <label htmlFor={newHeight}>height:</label>
                  <input type="text" id={newHeight} className="form-control form-control-sm" value={height} onChange={this.changeHeight} />
                </div>
              </div>
              <div className="form-group">
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" id={lockAspectRatioCheck} checked={isLockAspectRatio} onChange={this.changeLockAspectRatio} />
                  <label className="form-check-label" htmlFor={lockAspectRatioCheck}>Lock aspect ratio</label>
                </div>
              </div>
            </div>

            <div className="card-footer text-right">
              <button type="button" className="btn btn-primary btn-sm" onClick={this.updateSelector}>Save changes</button>
            </div>
          </div>

        </ReactModal>
      </div>
    );
  }
}

/**
 * 領域選択の範囲を管理します。
 */
class SizeManager {
  /**
   * コンストラクタです。
   */
  constructor(scale = 1) {
    this.scale = scale;
    this.init();
  }

  /**
   * サイズを初期化します。
   */
  init() {
    this.forCanvas = {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    };
    this.forImage = {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    };
  }

  /**
   * 縦横のサイズのマイナス値を補正します。
   */
  correctMinusValue() {
    [this.forCanvas, this.forImage] = [this.forCanvas, this.forImage].map((pSize) => {
      const size = pSize;

      // 横のサイズが負の場合は補正する
      if (size.width < 0) {
        size.width = Math.abs(size.width);
        size.x -= size.width;
      }
      // 縦のサイズが負の場合は補正する
      if (size.height < 0) {
        size.height = Math.abs(size.height);
        size.y -= size.height;
      }

      return size;
    });
  }

  /**
   * スケールを設定します。
   * @param {*} scale
   */
  setScale(scale) {
    this.scale = scale;

    this.forCanvas.x = this.forImage.x * scale;
    this.forCanvas.y = this.forImage.y * scale;
    this.forCanvas.width = this.forImage.width * scale;
    this.forCanvas.height = this.forImage.height * scale;
  }

  /**
   * スケールを取得します。
   */
  getScale() {
    return this.scale;
  }

  /**
   * キャンバス縮尺のサイズを設定します。
   * @param {*} size
   */
  setForCanvas(size) {
    if (size.x !== undefined) {
      this.forCanvas.x = size.x;
      this.forImage.x = size.x / this.scale;
    }
    if (size.y !== undefined) {
      this.forCanvas.y = size.y;
      this.forImage.y = size.y / this.scale;
    }
    if (size.width !== undefined) {
      this.forCanvas.width = size.width;
      this.forImage.width = size.width / this.scale;
    }
    if (size.height !== undefined) {
      this.forCanvas.height = size.height;
      this.forImage.height = size.height / this.scale;
    }
  }

  /**
   * キャンバス縮尺のサイズを取得します。
   */
  getForCanvas() {
    return this.forCanvas;
  }

  /**
   * イメージ縮尺のサイズを設定します。
   * @param {*} size
   */
  setForImage(size) {
    if (size.x !== undefined) {
      this.forImage.x = size.x;
      this.forCanvas.x = size.x * this.scale;
    }
    if (size.y !== undefined) {
      this.forImage.y = size.y;
      this.forCanvas.y = size.y * this.scale;
    }
    if (size.width !== undefined) {
      this.forImage.width = size.width;
      this.forCanvas.width = size.width * this.scale;
    }
    if (size.height !== undefined) {
      this.forImage.height = size.height;
      this.forCanvas.height = size.height * this.scale;
    }
  }

  /**
   * イメージ縮尺のサイズを取得します。
   */
  getForImage() {
    return this.forImage;
  }

  /**
   * イメージ縮尺のサイズを整数値で取得します。
   */
  getForImageWithRound() {
    return {
      x: Math.round(this.forImage.x),
      y: Math.round(this.forImage.y),
      width: Math.round(this.forImage.width),
      height: Math.round(this.forImage.height),
    };
  }
}

CropTool.propTypes = {
  getCanvasInf: PropTypes.func.isRequired,
  getImageInf: PropTypes.func.isRequired,
  getImage: PropTypes.func.isRequired,
  setImage: PropTypes.func.isRequired,
};
