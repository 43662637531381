import React from 'react';
import PropTypes from 'prop-types';

import Style from './DragAndDrop.module.scss';


/**
 * ドラッグ&ドロップコンポーネントです。
 */
export default class DragAndDrop extends React.Component {
  /**
   *
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.state = {
      isDropOver: false,
    };
  }

  /**
   *
   * @param {*} event
   */
  onDragOver(event) {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
    this.showDropping();
  }

  /**
   *
   * @param {*} event
   */
  onDragLeave() {
    this.hideDropping();
  }

  /**
   *
   * @param {*} event
   */
  onDrop(event) {
    event.preventDefault();
    this.hideDropping();
    const { files } = event.dataTransfer;
    this.loadFiles(files);
  }

  /**
   * ファイルのヘッダー情報から MIME TYPE を特定します。
   * @param {*} header ファイルヘッダー情報
   */
  static getMimeType(header) {
    let retv;

    if (header.startsWith('89504e47')) {
      retv = 'image/png';
    } else if (header.startsWith('424d')) {
      retv = 'image/bmp';
    } else if (header.startsWith('47494638')) {
      retv = 'image/gif';
    } else if (header.startsWith('ffd8ff')) {
      retv = 'image/jpeg';
    }

    return retv;
  }

  /**
   *
   */
  showDropping() {
    const { isDropOver } = this.state;

    if (!isDropOver) {
      this.setState({ isDropOver: true });
    }
  }

  /**
   *
   */
  hideDropping() {
    this.setState({ isDropOver: false });
  }

  /**
   *
   * @param {*} files
   */
  loadFiles(files) {
    const { onLoadImage } = this.props;
    const file = files[0];

    if (file.type.indexOf('image/') === 0) {
      Promise.resolve()
        .then(() => new Promise(((resolve, reject) => {
          // ヘッダーを読み取り、画像の MIME TYPE を特定する

          /* global FileReader */
          const reader = new FileReader();

          reader.onloadend = (e) => {
            const arr = (new Uint8Array(e.target.result)).subarray(0, 150);

            let header = '';
            for (let i = 0; i < arr.length; i += 1) {
              header += arr[i].toString(16);
            }
            const mimeType = DragAndDrop.getMimeType(header);

            if (mimeType !== undefined) {
              resolve(mimeType);
            } else {
              reject(new Error(`MIME type is ${mimeType}.`));
            }
          };
          reader.readAsArrayBuffer(file);
        })))
        .then(mimeType => new Promise(((resolve) => {
          // 画像を読み込む

          /* global FileReader */
          const reader = new FileReader();

          reader.onloadend = () => {
            // 読み込んだ画像を画面に表示
            onLoadImage(reader.result, mimeType, file.name);
            resolve();
          };

          reader.readAsDataURL(file);
        })))
        .catch((error) => {
          console.error(error);
        });
    }
  }

  /**
   *
   */
  render() {
    const { activate } = this.props;
    const { isDropOver } = this.state;
    return (
      <div className={[Style.outer, activate ? '' : Style.inactive].join(' ')}>
        <div
          className={[Style.inner, isDropOver ? Style.dropOver : ''].join(' ')}
          onDragOver={e => this.onDragOver(e)}
          onDragLeave={e => this.onDragLeave(e)}
          onDrop={e => this.onDrop(e)}
        >

          <div className={Style.icon}><i className="material-icons size-large color-gray">file_copy</i></div>
        </div>
      </div>
    );
  }
}

DragAndDrop.propTypes = {
  onLoadImage: PropTypes.func.isRequired,
  activate: PropTypes.bool.isRequired,
};
