import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import Jimp from 'jimp';

import Style from './SaveTool.module.scss';

/* global Blob, window, document */

const MODE_JPG = 'MODE_JPG';
const MODE_PNG = 'MODE_PNG';

/**
 * セーブツールコンポーネントです。
 */
export default class SaveTool extends React.Component {
  /**
   * コンストラクタです。
   * @param {*} props
   */
  constructor(props) {
    super(props);


    this.state = {
      modalIsOpen: false,
      mode: MODE_JPG,
      quality: 90,
      rgba: true,
      filterType: Jimp.PNG_FILTER_AUTO,
      deflateLevel: 9,
      deflateStrategy: 3,
    };
  }

  /**
   * モーダルダイアログを開きます。
   */
  openModal = () => {
    const { getImageInf } = this.props;

    const imageInf = getImageInf();

    this.setState({
      modalIsOpen: true,
      fileName: imageInf.fileName,
      mode: imageInf.mimeType === Jimp.MIME_PNG ? MODE_PNG : MODE_JPG,
    });
  }

  /**
   * モーダルダイアログが表示された後に呼ばれます。
   */
  afterOpenModal = () => {
  }

  /**
   * モーダルダイアログを閉じます。
   */
  closeModal = () => {
    this.setState({ modalIsOpen: false });
  }

  /**
   * 「fileName」の変更を受け取ります。
   */
  changeFileName = (e) => {
    const fileName = e.target.value;

    this.setState({ fileName });
  }


  /**
   * 保存する画像のファイルタイプの変更を受け取ります。
   */
  changeMode = (e, mode) => {
    this.setState({ mode });
  }

  /**
   * 「JPEG の画質」の変更を受け取ります。
   */
  changeQuality = (e) => {
    const quality = e.target.value;

    if (quality < 0 || quality > 100) {
      return;
    }

    this.setState({ quality });
  }

  /**
   * 「PNG の透過」の変更を受け取ります。
   */
  changeRgba = (e) => {
    const rgba = e.target.value;

    this.setState({ rgba });
  }

  /**
   * 「PNG の filterType」の変更を受け取ります。
   */
  changeFilterType = (e) => {
    const filterType = e.target.value;

    this.setState({ filterType });
  }

  /**
   * 「PNG の deflateLevel」の変更を受け取ります。
   */
  changeDeflateLevel = (e) => {
    const deflateLevel = e.target.value;

    this.setState({ deflateLevel });
  }

  /**
   * 「PNG の deflateStrategy」の変更を受け取ります。
   */
  changeDeflateStrategy = (e) => {
    const deflateStrategy = e.target.value;

    this.setState({ deflateStrategy });
  }

  /**
   * 画像ファイルをダウンロードします。
   */
  saveImage = () => {
    const { getImage, getImageInf, clearProgress } = this.props;
    const {
      fileName, mode, quality, rgba, filterType, deflateLevel, deflateStrategy,
    } = this.state;

    const mimeType = mode === MODE_PNG ? Jimp.MIME_PNG : Jimp.MIME_JPEG;

    this.closeModal();

    Promise.resolve()
      .then(() => new Promise(((resolve, reject) => {
        // 保存前処理

        Jimp.read(getImage()).then((jimpImage) => {
          if (mode === MODE_JPG) {
            jimpImage.quality(quality);
          } else if (mode === MODE_PNG) {
            jimpImage.rgba(rgba);
            jimpImage.filterType(filterType);
            jimpImage.deflateLevel(deflateLevel);
            jimpImage.deflateStrategy(deflateStrategy);
          }

          resolve(jimpImage);
        }).catch((err) => {
          reject(err);
        });
      })))
      .then(jimpImage => new Promise(((resolve, reject) => {
        // Jimp の画像データを base64 に変換

        jimpImage.getBase64Async(getImageInf().mimeType).then((imageSrc) => {
          resolve(imageSrc);
        }).catch((err) => {
          reject(err);
        });
      })))
      .then(imageSrc => new Promise(((resolve) => {
        // 画像を保存する

        clearProgress();

        if (window.navigator.msSaveBlob) {
          // Windows 系ブラウザの場合
          const bin = window.atob(imageSrc.replace(/^.*,/, ''));
          const buffer = new Uint8Array(bin.length);
          for (let i = 0; i < bin.length; i += 1) {
            buffer[i] = bin.charCodeAt(i);
          }

          const blob = new Blob([buffer.buffer], { type: mimeType });
          window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
          // それ以外のブラウザの場合
          const element = document.createElement('a');
          document.body.appendChild(element);
          element.setAttribute('href', imageSrc);
          element.setAttribute('download', fileName);
          element.style.display = 'none';
          element.click();
          document.body.removeChild(element);
        }

        resolve();
      })))
      .catch((error) => {
        clearProgress();
        console.error(error);
      });
  }

  /**
   * 描画します。
   */
  render() {
    const {
      modalIsOpen,
      fileName,
      mode,
      quality,
      rgba,
      filterType,
      deflateLevel,
      deflateStrategy,
    } = this.state;

    const newFileName = 'fileName';
    const newQuality = 'quality';
    const newRgba = 'rgba';
    const newFilterType = 'filterType';
    const newDeflateLevel = 'deflateLevel';
    const newDeflateStrategy = 'deflateStrategy';


    return (
      <div>
        <button type="button" className="btn tool" onClick={this.openModal}><i className="material-icons">save_alt</i></button>

        <ReactModal
          isOpen={modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          className={Style.modalDialog}
          overlayClassName={Style.modalOverlay}
          contentLabel="Save Modal"
        >

          <div className="card">
            <div className="card-header">
              Save
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="card-body">

              <form>
                <div className="form-group">
                  <label htmlFor={newFileName}>file name:</label>
                  <input type="text" className="form-control" id={newFileName} value={fileName} onChange={this.changeFileName} />
                </div>
              </form>

              <nav className={Style.tab}>
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <button type="button" className={['nav-link btn btn-link', mode === MODE_JPG ? 'active' : ''].join(' ')} href="#" onClick={e => this.changeMode(e, MODE_JPG)}>JPG</button>
                  </li>
                  <li className="nav-item">
                    <button type="button" className={['nav-link btn btn-link', mode === MODE_PNG ? 'active' : ''].join(' ')} href="#" onClick={e => this.changeMode(e, MODE_PNG)}>PNG</button>
                  </li>
                </ul>
              </nav>

              <form className={[Style.form, mode === MODE_JPG ? Style.show : Style.hide].join(' ')}>
                <div className="form-group row">
                  <label htmlFor={newQuality} className="col-sm-5 col-form-label">quality:</label>
                  <div className="col-sm-7">
                    <input type="number" className="form-control" id={newQuality} value={quality} onChange={this.changeQuality} />
                  </div>
                </div>
              </form>

              <form className={[Style.form, mode === MODE_PNG ? Style.show : Style.hide].join(' ')}>
                <div className="form-group row">
                  <label htmlFor={newRgba} className="col-sm-5 col-form-label">rgba:</label>
                  <div className="col-sm-7">
                    <select className="form-control" id={newRgba} name={newRgba} value={rgba} onChange={this.changeRgba}>
                      <option value="true">TRUE (default)</option>
                      <option value="false">FALSE</option>
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor={newFilterType} className="col-sm-5 col-form-label">filter type:</label>
                  <div className="col-sm-7">
                    <select className="form-control" id={newFilterType} name={newFilterType} value={filterType} onChange={this.changeFilterType}>
                      <option value={Jimp.PNG_FILTER_AUTO}>AUTO (default)</option>
                      <option value={Jimp.PNG_FILTER_NONE}>NONE</option>
                      <option value={Jimp.PNG_FILTER_SUB}>SUB</option>
                      <option value={Jimp.PNG_FILTER_UP}>UP</option>
                      <option value={Jimp.PNG_FILTER_AVERAGE}>AVERAGE</option>
                      <option value={Jimp.PNG_FILTER_PATH}>PATH</option>
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor={newDeflateLevel} className="col-sm-5 col-form-label">deflate level:</label>
                  <div className="col-sm-7">
                    <select className="form-control" id={newDeflateLevel} name={newDeflateLevel} value={deflateLevel} onChange={this.changeDeflateLevel}>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9 (default)</option>
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor={newDeflateStrategy} className="col-sm-5 col-form-label text-nowrap">deflate strategy:</label>
                  <div className="col-sm-7">
                    <select className="form-control" id={newDeflateStrategy} name={newDeflateStrategy} value={deflateStrategy} onChange={this.changeDeflateStrategy}>
                      <option value="0">DEFAULT</option>
                      <option value="1">FILTERED</option>
                      <option value="2">HUFMAN ONLY</option>
                      <option value="3">RLE (default)</option>
                    </select>
                  </div>
                </div>
              </form>

            </div>

            <div className="card-footer text-right">
              <button type="button" className="btn btn-primary btn-sm" onClick={this.saveImage}>Save Image</button>
            </div>
          </div>

        </ReactModal>
      </div>
    );
  }
}

SaveTool.propTypes = {
  getImageInf: PropTypes.func.isRequired,
  getImage: PropTypes.func.isRequired,
  clearProgress: PropTypes.func.isRequired,
};
