import React from 'react';
import ReactDOM from 'react-dom';
import ReactModal from 'react-modal';
import ReactGA from 'react-ga';

import Main from './Main';

/* global window */

ReactModal.setAppElement('#root');

ReactGA.initialize('UA-127291450-1');
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  <Main />,
  /* global document */
  document.getElementById('root'),
);
